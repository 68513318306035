.title {
  font-weight: 400;
  color: #214254;
  margin-bottom: 5px;
}

.subtitle {
  font-weight: 400;
  color: #214254;
  opacity: 0.5;
}

.imagesContainer {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding-left: 5px;
  width: 130px;
}

.image {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  margin-left: -5px;
  box-shadow: 0 0 0 2px white, 0 0 0 3px rgba(0, 0, 0, 0.2),
  0 0 0 5px white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  img {
    width: 30px;
    height: 30px;
  }

  &:first-child {
    margin: 0;
    z-index: auto;
  }

  &:nth-child(2) {
    z-index: -1;
  }

  &:nth-child(3) {
    z-index: -2;
  }

  &:last-child {
    z-index: -3;
  }

}