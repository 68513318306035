.quill {
    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid #c2deeb;
        background: #c2deeb;
    }

    .ql-container.ql-snow {
        border: none;
    }
}