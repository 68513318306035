.input {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  position: relative;

  input {
    outline: 0;
    border: none;
    padding: 0;
    margin: 0;
    min-width: min-content;
    width: 100%;
    background-color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;

    &::placeholder {
      font-weight: 300;
      opacity: .5;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.count {
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 8px;
  line-height: 10px;

  &__warning {
    color: red;
  }
}



.medium {
  height: 40px;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
}

.primary {
  border-color: #c2deeb;
  background: #ffffff;

  svg {
    color: #214254;
  }
}

.secondary {
  border-color: transparent;
  background-color: #F4F9FB;
  color: #214254;

  svg {
    color: #739AAC;
  }

  input {
    color: #214254;

    &::placeholder {
      color: #739AAC;
      opacity: 1;
    }
  }
}

.invalid {
  border-color: red;
}

.fullWidth {
  width: 100%;
}

.iconBefore,
.iconAfter {
  min-width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;

    &:active {
      transform: scale(1.2);
    }
  }
}

.iconBefore {
  margin-right: 10px;
}

.iconAfter {
  display: flex;
  margin-left: 10px;
  cursor: pointer;
  justify-self: flex-end;
}

.disabled {
  opacity: .5;
  cursor: no-drop;
}