.form {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  row-gap: 20px;
}

.input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 50;
  margin-bottom: 1rem;
  width: 100%;
}

.label {
  // font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 190%;
  color: #5a7486;
}

.input,
.select {
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 10px;
  outline: none;

  &::placeholder {
    font-style: italic;
    opacity: 0.5;
  }
}

.info_container {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: var(--secondary-color);
  margin: 0 20px;
  //box-shadow: 0 5px 20px rgba(21, 30, 34, 0.1);
  height: 60px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px;
  opacity: 0.7;
  overflow-y: auto;
}

.text_red {
  color: red;
}

.loading_wrapper,
.buttons_wrapper {
  width: 30%;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.btn {
  border-radius: 10px;
  border: 2px solid transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0 1rem;
  letter-spacing: 1px;

  &:hover {
    border: 2px solid var(--dark-color);
    color: var(--dark-color);
    cursor: pointer;
  }
}

.btn_check {
  background-color: var(--primary-color);
}

.btn_clear {
  background-color: #ff8080;
}

input[id='img_upload'] {
  display: none;
}

.custom_img_upload {
  width: 110px;
  height: 110px;
  background-color: #4288f0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_image {
  width: 110px;
  height: 110px;
  background-color: var(--light-blue-color);
  border-radius: 15px;
}

.hr {
  color: inherit;
  border: 0;
  border-top: 1px solid #c2deeb;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: var(--secondary-color);
}

.pros_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-right: 20px;
}

.main_product_content {
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(21, 30, 34, 0.2);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .image {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    position: relative;
    top: 0;
    left: 0;
    z-index: 10;

    img {
      width: 100%;
      height: auto;
    }
  }

  .title {
    font-size: 18px;
    line-height: 120%;
    color: var(--dark-color);
    text-align: start;
    text-transform: capitalize;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    letter-spacing: 0.1em;
  }
}

.buying_guide_image {
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--primary-color);
    width: 300px;
    height: 400px;
    border-radius: 18px;

    .image {
      height: auto;
      width: 100%;
    }
  }

  .empty_image {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    text-align: center;
    line-height: 200%;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
}

.banner_images_list {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 180px;
  position: relative;
  overflow-x: auto;
  box-shadow: 0 5px 20px rgba(21, 30, 34, 0.2);
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 10px 10px 0;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background-color: var(--middle-blue-color);
    padding: 0 10px;
  }
}

.gallery_active {
  border-radius: 5px;
  background-color: var(--middle-blue-color);
}

.similar_products_grid {
  margin-bottom: 50px;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (max-width: 1260px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.amazon_reviews_slider {
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
  padding: 20px 10px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background-color: var(--middle-blue-color);
  }
}

.title_wrapper {
  display: flex;
  column-gap: 10px;
}


