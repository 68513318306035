$orange: #dea14b;

.deal_product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  transition: .2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
}

.container {
  display: flex;
  flex: 1;
}

.image_wrapper {
  @extend .container;
  border-bottom: 1px solid #c2deeb;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  img {
    max-width: 100%;
    height: auto;
  }
}

.data_container {
  @extend .container;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .5px;
  line-height: 18px;
  margin-top: 15px;
  text-align: center;
  color: var(--secondary-color);
}

.sale_price {
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-align: center;
}

.price {
  margin-top: 5px;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 13px;
  opacity: .35;
  text-align: center;
  text-decoration-line: line-through;
}

.sale {
  background-color: #dea14b;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  margin-left: 4px;
  padding: 4px;
}

.price_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button {
  margin-top: 15px;
}

.page_url {
  width: 100%;
  text-decoration: none;
}

.sale_price_wrapper {
  display: flex;
  align-items: center;
}
