.products_list {
  position: relative;
  min-width: 100%;
  display: flex;
  margin: -10px;
}

.notification_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 100%;
}

.slider::-webkit-scrollbar {
  height: 3px;
}

.slider::-webkit-scrollbar-track {
  background-color: transparent;
}

.slider::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 5px;
  background-color: var(--middle-blue-color);
}
