.date_container {
  padding: 0;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .close_button {
    align-self: flex-end;
  }
}

.date_button {
  justify-content: flex-start;
}

