.uploader {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  text-transform: capitalize;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .media {
    background: #000;
  }

  video {
    width: 100%;
    height: auto;
  }

  img {
    background-color: #060f14;
    height: 100%;
    width: auto;
  }

  &__invalid {
    color: #D42948;
    border-color: #D42948;
    background-color: #F6D4DA;

    .drop_area svg {
      color: #D42948;
    }
  }
}

.drop_area {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  cursor: pointer;
  padding: 20px;

  svg {
    color: var(--primary-color);
    width: 25px;
    height: 25px;
  }

  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  text-align: center;

  input {
    display: none;
  }

}

.bg_drag_over {
    background-color: rgba(0, 0, 0, 0.1);
}