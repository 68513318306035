.user_card {
  background: #ffffff;
  border: 1px solid #F2F6F8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;

  .user_data {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .avatar {
      border-radius: 50px;
      width: 45px;
      height: 45px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      font-family: 'Rubik', sans-serif;

      &__name {
        font-size: 14px;
        color: #214254;
      }

      &__role {
        font-size: 12px;
        color: #6E8490;
      }
    }
  }

}