.ai_label {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  height: 28px;
  border-radius: 12px;
  padding: 5px 15px;
  background-color: #E7F8EF;
  color: #62D294;
  font-size: 14px;
  font-weight: 300;

  & svg {
    width: 26px;
    height: 26px;
  }
}

.question {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20px;
  height: 20px;
  cursor: help;

  color: var(--primary-color);

  svg {
    width: 75%;
    height: 75%;

  }
}