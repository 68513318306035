.attribute {
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 15px;
  margin: 0 -10px;
  background-color: #f3f9fa;
  position: relative;
  height: 180px;

  &:nth-child(2n) {
    background-color: #ffffff;
  }
}

.bottom_line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 5px;
}


.attribute_item_base {
  user-select: none;
  cursor: pointer;
}

.action_panel {
  border-radius: 8px;
  padding: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.arrow_up {
  transform: rotate(180deg);
}

.button {
  width: 30px;
}

.panel_wrapper {
  padding-right: 5px;
  position: absolute;
  left: 0;
  transform: translate(-100%, -50%);
  top: 50%;
  z-index: 100;
}

.value {
  display: flex;
  flex: 3;
}

.score {
  flex: 1;
}