.suggestion {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.suggestion_text {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.suggestion_name {
  @extend .suggestion_text;
  text-decoration: underline;
  color: var(--primary-color);
}

.suggestion_error {
  @extend .suggestion_text;
  color: red;
}