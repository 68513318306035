.order_status {
  max-width: 170px;
  min-height: 24px;
  padding: 6px 15px;
  border-radius: 20px;
  border: 1px solid;
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;

  &__disabled {
    cursor: no-drop;
  }

  &__success {
    background-color: #E7F8EF;
    color: #62D294;

  }

  &__warning {
    background-color: #FFF5E8;
    color: #FFAE3C;
  }

  &__info {
    background-color: #F4F9FB;
    color: #6F8490
  }

  &__outlined {
    border-color: #E0EFF5;
    background-color: inherit;
  }
}