.item_wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  max-height: 400px;
  width: calc(100% / 5);
  min-height: 100%;
}

.items_container {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: -10px;
}