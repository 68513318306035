.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 0;
  transition: .3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.035);

  }

  .image_wrapper {
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
