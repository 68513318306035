.text_transform {
  text-transform: capitalize;
}

.table {
  thead tr th:nth-child(1) {
    width: 20%;
  }

  thead tr th:nth-child(2) {
    width: 5%;
  }

  thead tr th:nth-child(3) {
    width: 10%;
  }

  thead tr th:nth-child(4) {
    width: 10%;
  }

  thead tr th:nth-child(5) {
    width: 10%;
  }

  thead tr th:nth-child(6) {
    width: 10%;
  }

  thead tr th:nth-child(7) {
    width: 10%;
  }

  thead tr th:nth-child(8) {
    width: 10%;
  }

  thead tr th:nth-child(9) {
    width: 15%;
  }
}