.container {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.image_container {
  width: 100px;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }
}

.image_uploader {
  border-radius: 100%;
}

.fields_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
}
