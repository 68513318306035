@import "../../../../assets/scss/root";

.pages {
  display: flex;
  flex-direction: column;

  .page_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 8px;
  }
}

.pages_container {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(21, 30, 34, 0.03);
  background-color: #fff;
}
