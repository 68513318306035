.items_container {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  min-height: 100%;
  margin: -10px;
}

.item_wrapper {
  box-sizing: border-box;
  width: calc(100% / 4);
  padding: 10px;
}

.pros_wrapper {
  @extend .item_wrapper;
}

.faq_wrapper {
  @extend .item_wrapper;
  height: 270px;
}

.add_new_button {
  height: 50%;
  width: auto;
  color: #c2deeb;
}

.add_new_container {
  width: 100%;
  height: 100%;
  transition: all 0.2s;

  &:hover {
    background-color: #c2deeb;

    svg {
      color: #FFFFFF
    }
  }
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: var(--secondary-color);
}
