.order_item_details {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;


  & + & {
    border-top: 1px solid #F3F5F5;
    padding-top: 15px;

  }

  &:last-child {
    margin-bottom: 0;
  }

  .item_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    column-gap: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item_main {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    row-gap: 15px;

    .main_item_wrapper {
      border-bottom: 1px solid #F3F5F5;

      &:last-child {
        border-bottom: none;
      }
    }

  }

  .item_top + .item_main {
    border-top: 1px solid #F3F5F5;
  }

}

.table_without_border {
  border: none;
  padding: 0;

  thead tr th:nth-child(1) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(2) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(3) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(4) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(5) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(6) {
    width: calc(100% / 7);
  }

  thead tr th:nth-child(7) {
    width: calc(100% / 7);
  }
}

.first_table_column:first-child {
  font-weight: 400;
}

.capitalize_text {
  text-transform: capitalize;
}