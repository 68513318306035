.item_wrapper {
  box-sizing: border-box;
  width: 20%;
  padding: 10px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.items_container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
}
