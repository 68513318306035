.container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
}

.image_container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 140px;
  max-width: 100px;
}

.fields_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: 10px;
}
