@import "src/assets/scss/root";

.select {
  box-sizing: border-box;
  position: relative;
  display: flex;
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 10px 15px;
  outline: none;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  text-align: left;
  font-family: Rubik, sans-serif;


  &__small {
    height: 30px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 116%;
    font-weight: 300;
    border-radius: 8px;
  }

  &__medium {
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 116%;
    font-weight: 300;
    border-radius: 10px;
  }

  &__fullwidth {
    width: 100%;
  }

  &__disabled {
    opacity: .5;
    cursor: no-drop;
  }

  .input {
    border: none;
    background: none;
    text-transform: capitalize;
    outline: none;
    padding: 0;
    font-family: inherit;
    cursor: pointer;
    color: inherit;
    font-weight: 300;

    &::placeholder {
      font-weight: 300;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  .arrow {
    display: flex;
    position: absolute;
    top: 50%;
    right: 15px;
    height: 100%;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    z-index: 5;
    background-color: inherit;
    padding-left: 5px;

    svg {
      transition: transform 0.3s;
      width: 12px;
      height: 12px;
    }

    &__open {
      svg {
        transform: rotate(-180deg)
      }
    ;
    }
  }

  &__secondary {
    border-color: transparent;
    background-color: #F4F9FB;
    color: #214254;


    svg {
      color: #739AAC
    }


    input::placeholder {
      color: #739AAC
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    box-shadow: 1px 5px 10px rgba(21, 30, 34, 0.2);
    background-color: #fff;
    list-style: none;
    border-radius: 8px;
    overflow: auto;
    line-height: 116%;

    &__visible {
      display: flex;
      z-index: 10;
    }

    .option {
      padding: 5px 0;

      &__first {
        font-style: italic;
      }

      &__checked {
        font-weight: 600;
      }
    }

  }

  .count_label {
    @include delete-button;
    color: white;
    font-size: 14px;
  }

  .base_select {
    display: none;
  }

  &__invalid {
    border: 1px solid red;
  }
}




