.title {
  display: flex;
  min-width: max-content;
  align-items: baseline;
  column-gap: 10px;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    color: var(--dark-color);
  }


  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }


  .icon {
    transform: rotate(90deg);
  }

  span {
    color: #214254;
    font-size: 13px;
    line-height: 153%;
  }
}