.avatar_uploader {
  padding: 10px;
  cursor: pointer;

  &__img {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #739AAC;
    background-color: #F4F9FB;
    border-radius: 100%;

    svg {
      width: 50%;
      height: 50%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translate(-50%, -50%);
    }

  }

  &__small {
    width: 150px;
    height: 150px;
  }

  &__middle {
    width: 200px;
    height: 200px;
  }

  &__large {
    width: 250px;
    height: 250px;
  }

  .html_input {
    display: none;
  }

  .error {
    color: red;
    font-size: 12px;
    font-weight: 300;
    background-color: white;
    z-index: 9;
  }
}

