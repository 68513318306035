.container {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  min-width: 100%;
  height: 100%;
  align-items: center;
  row-gap: 20px;
  background-color: #FFFFFF;

  &__deleted {
    opacity: 0.2;
    border-color: red;
  }

  .video_wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    height: 50%;
    background: #000;
    align-items: center;

    .video {
      width: 100%;
      height: 100%;
    }
  }
}
