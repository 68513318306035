.container {
  overflow: initial;
  position: relative;
  width: max-content;
  height: max-content;

  &:hover {
    .title {
      display: flex;
      z-index: 100000;
    }
  }
}

.title {
  position: absolute;
  box-sizing: border-box;
  display: none;
  padding: 7px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 1);
  color: #FFFFFF;
  font-size: 12px;
  line-height: 116%;
  font-weight: 300;
  z-index: 10000000;
  width: max-content;
  max-width: 500px;
  word-wrap: break-word;
  text-align: justify;;

  &__top {
    left: 50%;
    top: 0;
    transform: translate(-50%, calc(-100% - 5px));
  }

  &__bottom {
    left: 50%;
    top: 100%;
    transform: translate(-50%, 5px);
  }

  &__left {
    left: 0;
    top: 50%;
    transform: translate(calc(-100% - 5px), -50%);
  }

  &__right {
    left: 100%;
    top: 50%;
    transform: translate(5px, -50%);
  }
}
