.divider {
  border-width: 0 0 thin;
  border-style: solid;

  &__horizontal {
    width: 100%;
    margin: 10px 0;
  }

  &__vertical {
    height: 100%;
    margin: 0 10px;
  }

  &__primary {
    border-color: var(--primary-color);

  }

  &__secondary {
    border-color: #c2deeb;
  }
}