.input {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 11px 15px;
  outline: none;
  cursor: pointer;
  text-align: left;
  font-family: Rubik, sans-serif;

  &__fullwidth {
    width: 100%;
  }

  &__disabled {
    opacity: .5;
    cursor: no-drop;
  }

  &__unresizable {
    textarea {
      resize: none;
    }
  }

  .icon_before,
  .icon_after {
    width: 16px;
    height: 16px;
  }

  .icon_before + textarea,
  textarea + .icon_after {
    margin-left: 10px;
  }

  textarea {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    min-height: 100%;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    font-family: inherit;
    width: 100%;
    cursor: pointer;
    color: inherit;

    &::placeholder {
      opacity: .5;
      font-size: 14px;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  &__secondary {
    border-color: transparent;
    background-color: #F4F9FB;
    color: #214254;

    svg {
      color: #739AAC;
    }

    & textarea::placeholder {
      color: #739AAC;
      opacity: 1;
    }
  }

  &__invalid {
    border: 1px solid red;
  }

  .count {
    position: absolute;
    right: 5px;
    top: 2px;
    font-size: 8px;
    line-height: 10px;

    &__warning {
      color: red;
    }
  }
}
