.compare_product {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  min-width: 300px;
  max-width: 300px;
  border-radius: 0;
  flex: 1;

  & + & {
    border-left: none;
  }

  &__title {
    height: 110px;
    overflow: hidden;
  }

  &__rating {
    padding: 10px 0;
  }
}

.compare_product_image_wrapper {
  padding: 10px;
  height: 200px;
}

.compare_product_rating_wrapper {
  padding: 10px 0;
}

.attributes_container {
  display: flex;
  flex-direction: column;
}

