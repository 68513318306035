.container {
  display: flex;
  align-items: center;
  column-gap: 7px;
  min-width: 100%;
}

.select {
  width: 300px;
}
