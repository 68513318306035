.modal_table {
  thead tr th:nth-child(1) {
    width: 40%;
  }

  thead tr th:nth-child(2) {
    width: 10%;
  }

  thead tr th:nth-child(3) {
    width: 17%;
  }

  thead tr th:nth-child(4) {
    width: 13%;
  }
}

.modal_title {
  font-weight: 500;
  margin-bottom: 20px;
}

.footer_total_amount {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
}