@import "../../../../assets/scss/mixins.scss";

.btn_delete {
  @include delete-button;
}

.btn_edit {
  @include add-button;
  margin-top: 0px;
  float: right;
}

.btn_add {
  @include add-button;
  margin-top: 10px;
  margin-left: auto;
}

