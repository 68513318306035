@font-face {
  font-family: "Rubik";
  src: local("Rubik Light"), local("Rubik-Light"),
    url("../fonts/Rubiklight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), local("Rubik-Regular"),
    url("../fonts/Rubik.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Medium"), local("Rubik-Medium"),
    url("../fonts/Rubikmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Bold"), local("Rubik-Bold"),
    url("../fonts/Rubikbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: local("Circular Book"), local("Circular-Book"),
    url("../fonts/CircularStd-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: local("Circular Book Italic"), local("Circular-Book-Italic"),
    url("../fonts/CircularStd-BookItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: local("Circular Bold"), local("Circular-Bold"),
    url("../fonts/CircularStd-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: local("Circular Bold Italic"), local("Circular-Bold-Italic"),
    url("../fonts/CircularStd-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
