.form_item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .label {
    font-size: 12px;
    font-weight: 300;
    color: #6F8490;
  }

  .message {
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    background-color: transparent;
  }

  .error {
    color: red;
  }

  .success {
    color: #62D294;
  }

  .warn {
    color: #FFAE3C;
  }
}