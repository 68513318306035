.pages_filter{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: -5px;
}

.filter_elem_wrapper {
  padding: 5px;
  width: calc(100% / 4);
}