.reviews_container {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: 0 -10px -10px -10px;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background-color: var(--middle-blue-color);
  }
}

.review_card_wrapper {
  padding: 10px;
  width: calc(100% / 3);
  height: 350px;
  z-index: 20;
}

.movable {
  cursor: move;
}