.item_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: calc(100% / 4);
  height: 500px;
  position: relative;
}


.items_container {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: -10px;
}