.error_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;

  .main {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 200px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 100px;
      font-weight: 300;
      color: #5a7486;
    }

    .description {
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
      color: #5a7486;
    }
  }
}