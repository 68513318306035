.multi_input {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 7px 10px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  min-height: 40px;

  & > input {
    border: none;
    background: none;
    outline: none;
    padding: 0 10px 0 0;
    font-family: inherit;
    cursor: pointer;
    font-size: 14px;
    color: inherit;
    width: max-content;

    &::placeholder {
      font-size: 14px;
    }
  }

  .tag {
    color: #060F14;
    box-sizing: border-box;
    height: 26px;
    padding: 0 8px;
    border-radius: 5px;
    background-color: #E7F2F7;
    font-size: 12px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    text-transform: uppercase;

    svg {
      width: 8px;
      height: 8px;

    }

    svg path {
      fill: #6F8490;
    }

    .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }


  &__secondary {
    border-color: transparent;
    background-color: #F4F9FB;
    color: #214254;

    .tag {
      background: #FFFFFF;
    }
  ;
    svg {
      color: #739AAC
    }


    input::placeholder {
      color: #739AAC
    }
  }


}