.product_scheduler {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .logo {
    width: 130px;
  }

  .title {
    font-size: 24px;
    line-height: 125%;
    font-weight: 500;
  }

  .hint {
    font-size: 13px;
    line-height: 125%;
    font-weight: 400;
    margin-bottom: 31px;
  }

  .title + .hint {
    margin-top: 16px;
  }

  .logo + .title {
    margin-top: 40px;
  }

  .notification {
    position: absolute;
    left: 0;
    top: 0;
  }
}




