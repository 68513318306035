.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);

  svg {
    color: #6F8490;
  }
}

.title {
  font-weight: 400;
  line-height: 125%;
}

.image_wrapper {
  svg {
    width: 100%;
    height: auto;
  }
}

.small {
  padding: 10px;

  .title {
    font-size: 12px;
    margin-top: 5px;
  }

  .image_wrapper {
    width: 30px;
    height: 30px;
  }
}

.middle {
  padding: 15px;

  .title {
    font-size: 14px;
    margin-top: 10px;
  }

  .image_wrapper {
    width: 45px;
    height: 45px;
  }
}

.large {
  padding: 15px;

  .title {
    font-size: 18px;
    margin-top: 10px;
  }

  .image_wrapper {
    width: 60px;
    height: 60px;
  }
}

.fullWith {
  width: 100%;
}