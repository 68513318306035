.page_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rows_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.page_actions {
display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_actions_item {
  margin: 20px 0;
  width: 200px;
}

.save_actions {;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.loader {
  width: 100%;
  justify-content: center;
}

