.rowActions {
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin: 0;
}

.row_select {
  max-width: max-content;
  margin: 0;
}

.cell_name {
  font-size: 8px;
}

.mav_count {
  font-weight: 400;
  color: #214254;
  font-size: 14px;
}

table {
  thead {
    tr {
      th {

        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 25%;
        }

        &:nth-child(4) {
          width: 25%;
        }
      }
    }
  }
}


@media (max-width: 1480px) {
  table {
    thead {
      tr {
        th {

          &:nth-child(1) {
            width: 35%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 30%;
          }

        }
      }
    }
  }
}


@media (min-width: 1481px) and (max-width: 1650px) {
  table {
    thead {
      tr {
        th {

          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 25%;
          }
        }
      }
    }
  }
}

.publisherLogo svg {
  display: flex;
  align-items: center;
    height: 18px;
    width: auto;
}

