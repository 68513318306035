.checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > span {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 14px;
    line-height: 116%;

    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #C2DEEB;
      border-radius: 6px;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }

  & > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;

    &:disabled + span {
      opacity: .5;
      cursor: no-drop;
    }

    &:disabled + span:before {
      border-color: #0b76ef;
      opacity: .5;
    }

    &:checked + span::before {
      border-color: #0b76ef;
      background-color: #0b76ef;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.2499 5.64745L9.83271 0L11 1.17627L4.2499 8L0 3.70593L1.16646 2.52966L4.2499 5.64745Z' fill='white'/%3E%3C/svg%3E");
    }

    &:not(:disabled):not(:checked) + span:hover::before {
      border-color: #b3d7ff;
    }

    &:not(:disabled):active + span::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
    }

    &:focus + span::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:focus:not(:checked) + span::before {
      border-color: #80bdff;
    }
  }
}