.brand_input_wrapper {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.brands_list {
  text-align: left;
  position: absolute;
  top: 42px;
  left: 0;
  max-height: 200px;
  min-height: 35px;
  height: fit-content;
  width: 100%;
  box-shadow: 1px 5px 10px rgba(21, 30, 34, 0.2);
  background-color: #fff;
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  overflow: auto;
  z-index: 100;
}

.brand_item {
  padding: 4px 0;

  &:hover {
    background-color: var(--light-blue-color);
    cursor: pointer;
  }
}

.input {
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  font-size: 16px;
  height: 40px;

  &::placeholder {
    opacity: 0.5;
    font-size: 14px;
  }

  &:disabled {
    background-color: var(--light-blue-color);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.option {
  padding: 5px 10px;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 10px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .image_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }

  &:hover {
    background-color: #F4F9FB;
  }
}

.group_styles {
  display: flex;
  align-items: center;
  justify-content: space-between
};

.group_badge_styles {
  background-color: #EBECF0;
  border-radius: 2em;
  color: #172B4D;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  padding: 0.16666666666667em 0.5em;
  text-align: center,
};

.list {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;

  &.open {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
  }
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.arrow_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: all .3s;
  margin-left: auto;
  padding: 8px;

  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, .05);
  }
}

.arrow {
  width: 14px;
  height: 14px;
  color: #739AAC;
}

.selected {
  background-color: #F4F9FB;
}

.subOption {
  padding-left: 40px;
}
