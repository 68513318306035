.form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  width: 100%;
}

.asset_order {
  position: absolute;
  top: 25%;
  left: 25%;
  font-size: 80px;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #FFFFFF;
  filter: drop-shadow(0 0 2px #000000);
  opacity: 0.6;
}

.item_wrapper {
  box-sizing: border-box;
  width: 200px;
  padding: 10px;
  height: 200px;
  position: relative;

  &:nth-child(2):before {
    @extend .asset_order;
    content: '1';
  }

  &:nth-child(3):before {
    @extend .asset_order;
    content: '2';
  }

  &:nth-child(4):before {
    @extend .asset_order;
    content: '3';
  }

  &:nth-child(5):before {
    @extend .asset_order;
    content: '4';
  }
}

.items_container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: 0 -10px;
}