.image_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #EDEDED;
    width: 100%;
    height: 100%;
  }
}

.image {
  display: block;

  &_hidden {
    display: none;
  }
}


.skeleton {
  background: #EDEDED linear-gradient(
                  100deg,
                  rgba(255, 255, 255, 0) 40%,
                  rgba(255, 255, 255, .5) 50%,
                  rgba(255, 255, 255, 0) 60%
  );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}