.success_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--light-blue-color);
}

.notice {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  color: red;
  text-align: center;
  margin-bottom: 50px;
}

.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 50px;
}

.link {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  color: var(--primary-color);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    color: var(--secondary-color);
    text-decoration: underline;
  }
}

.btn_back {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  margin-top: 50px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.section_title {
  margin-top: 20px;
}
