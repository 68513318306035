.asset_order {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 80px;
  z-index: 2;
  color: #FFFFFF;
  filter: drop-shadow(0 0 2px #000000);
  opacity: 0.6;
}

.banner {
  position: relative;
  height: 280px;
  width: 100%;
  max-width: 1250px;
  border-radius: 15px;
  overflow: hidden;
}

.content_wrapper {
  display: flex;
  gap: 8px;
  height: 100%;
  filter: drop-shadow(1px 3px 10px rgba(21, 30, 34, 0.09));
}

.images_col {
  display: flex;
  flex-basis: 15%;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.image_small {
  flex: 2 30%;
  overflow: hidden;
  position: relative;

  &:before {
    @extend .asset_order;
    content: '2';
  }
}

.image_large {
  flex: 3 40%;
  overflow: hidden;
  border-radius: 0 15px 15px 0;
  position: relative;

  &:before {
    @extend .asset_order;
    content: '1';
  }

}

.image img  {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background-color: var(--light-blue-color);
}

.img_1 {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:last-child:before {
    @extend .asset_order;
    content: '3';
  }

  &:first-child:before {
    @extend .asset_order;
    content: '4';
  }
}

.img_2 {
  height: 100%;
  width: 100%;
  position: relative;
}

.img_3 {
  height: 100%;
  width: 100%;
  position: relative;
}

.banner_video_container {
  flex: 0 15%;
  width: 190px;
  height: 100%;
  position: relative;
}

.banner_video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 750px;
  max-width: 400px;
  background: #000;
  z-index: 16;
}
