.tag_creation_form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form_item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
}

.input {
  all: unset;
  border-bottom: 1px solid black;

  &::placeholder {
    font-weight: 300;
    font-size: 14px;
  }

  &__error {
    border-bottom-color: #ff4d4f;
  }
}

.asin {
  width: 120px;
}

.tags {
  width: 500px
}

.actions {
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-left: 10px;
}

.tags_container {
  display: flex;
  flex-direction: column;
}

.link_logo {
  width: 15px;
  height: auto;
  margin-right: 10px;
}

.input_wrapper {
  display: flex;
  width: max-content;
  position: relative;
  padding: 15px 5px;
}

.error_message {
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  left: 10px;
  top: 100%;
  transform: translateY(-100%);
}


.loader_container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background: white;
  opacity: 0.5;
}

.loader {
  z-index: 4;
}

.modal_body {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 250px;
  max-width: 700px;
  max-height: 700px;
}

.modal_subtitle {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.container {
  padding: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, .05);
}

.link_list {
  @extend .container;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 20px;
}

.modal_link_wrapper {
  @extend .container;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

}

.modal_link {
  width: 85%;
  overflow-wrap: break-word;
  font-style: italic;
  font-weight: 300;
}

.button_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

