.layout {
  display: flex;
  height: 100vh;

  .sidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 250px;
    max-width: 300px;
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-body);
    border-right: 1px solid #F2F6F8;
    transition: all 0.1s;

    &_hided {
      width: 88px;
      min-width: 0;
    }

    .logo_link {
      margin-bottom: 0;
    }

    .arrow_icon {
      transform: rotate(90deg);
      transition: all .3s;

      &__rotated {
        transform: rotate(-90deg);
      }
    }

    hr {
      margin: 1rem 0;
      color: #F2F6F8;
      border: 0;
      border-top: 1px solid;
      opacity: 0.25;
    }

    .dropdown {
      position: relative;

      .avatar-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        outline: 0;
        color: var(--secondary-color);

        &:hover {
          color: var(--primary-color);
        }

        &:after {
          display: inline-block;
          margin-left: 0.5em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }

        .avatar-img {
          border-radius: 50%;
          margin-right: 1rem;
          vertical-align: middle;
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;
    background-color: var(--bg-body);
    padding: 50px 50px 0 50px;
    overflow-y: auto;
  }
}
