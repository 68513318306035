.modal_content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  text-align: justify;

  &__messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 15px;

    .message {
      display: flex;
      flex-direction: column;
      padding: 10px;
      row-gap: 10px;

      &__title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
      }

      &__content {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 26px;
        margin: -5px;

        .item_wrapper {
          padding: 5px;
          width: calc(100% / 3);
        }

        &__item {
          color: #060F14;
          box-sizing: border-box;
          height: 26px;
          padding: 0 8px;
          border-radius: 5px;
          background-color: #E7F2F7;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
        }
      }
    }
  }

  &__actions {
    display: flex;
    padding: 20px 10px 10px;
    width: 100%;
    justify-content: center;
  }
}