.create_button_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.modal {
  height: 500px;
  width:450px;
}

.modal_body {
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  flex: 1;
  padding: 15px 0 0 0;
  column-gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #FFFFFF;
}

.title {
  font-size: 16px;
  font-weight: 500;
  opacity: .8;
}

.list {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 10px;
  padding: 10px 5px ;
}

.search_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 10px;
  padding: 0;
}

.section_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

}