@import "../../../../../../../assets/scss/mixins.scss";

.input {
  @include input;
}

input[id="asset_video_input"] {
  display: none;
}

.add_button {
  @include add-button;
}

.drop_area {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  cursor: pointer;

  svg {
    color: var(--primary-color);
    width: 25px;
    height: 25px;
  }

  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  text-align: center;

}

.bg_drag_over {
  @include bg-drag-over;
}

.bg_drag_leave {
  @include bg-drag-leave;
}

.card_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  height: 100%;
  align-items: center;

  .media_container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 50%;
    padding: 0;
    gap: 20px;
  }

    .video {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #000;
    }

}
