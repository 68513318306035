.input_wrapper{
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #c2deeb;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    text-align: left;
    font-family: Rubik, sans-serif;
    width: 100%;
    overflow: hidden;

    .input {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        font-family: inherit;
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: inherit;
    }    
}

