.title_wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: var(--secondary-color);
}