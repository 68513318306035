.loader {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  width: max-content;

  & > div {
    z-index: 20;
    border-radius: 100%;
    border: solid;
    border-color: #ddd;
    border-left: solid 4px var(--primary-color);

    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
  }

  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 116%;
    color: var(--primary-color);
    text-align: center;
    vertical-align: middle;
  }
;


  &__small {
    & > div {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }

    & > span {
      font-size: 12px;
    }
  }

  &__medium {
    & > div {
      border-width: 3px;
      width: 30px;
      height: 30px;
    }

    & > span {
      font-size: 14px;
    }
  }

  &__large {
    & > div {
      border-width: 4px;
      width: 45px;
      height: 45px;
    }

    & > span {
      font-size: 16px;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}