.container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  border-radius: 20px;
  padding: 10px;

  &__solid {
    border: 1px solid;
  }

  &__dashed {
    border: 1px dashed;
  }

  &__primary {
    border-color: var(--primary-color);
  }

  &__secondary {
    border-color: #c2deeb;
  }

  &__shadow {
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, .07);
  }

  &__checked {
    box-shadow: 0 0 0 3px rgba(98, 210, 148, 1);
  }
}
