@mixin asset-entity {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(21, 30, 34, 0.1);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
}

@mixin asset-entity-wrapper {
  width: 100%;
  border-radius: 10px;
  border: 1px dashed var(--primary-color);
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 10px;
}

@mixin input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 5;
  margin-bottom: 1rem;
}

@mixin input {
  background: #ffffff;
  border: 1px solid #c2deeb;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
  z-index: 2;
  width: 100%;

  &::placeholder {
    font-style: italic;
    opacity: 0.5;
  }
}

@mixin input-font {
  font-family: "Circular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: var(--secondary-color);
}

@mixin label-font {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 190%;
  color: #5a7486;
}

@mixin info-container {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: var(--secondary-color);
}

@mixin title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--dark-color);
  text-transform: capitalize;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 18px 0;
  min-height: 2.5em;
  text-align: center;
}

@mixin text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--secondary-color);
  text-align: center;
  overflow: hidden;
  min-height: 2.5em;
  letter-spacing: 0.1em;
}

@mixin image {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: auto;
    height: 100%;
  }
}

@mixin drop-area {
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  border: 1px dashed var(--primary-color);

  img {
    margin-bottom: 5px;
  }

  .preview {
    width: 100%;
    height: 100%;
  }
}

@mixin bg-drag-over {
  background-color: #4288f0;
}

@mixin bg-drag-leave {
  background-color: #fff;
}

@mixin add-button {
  width: 108px;
  height: 30px;
  color: #fff;
  background: #4288f0;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

@mixin delete-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--primary-color);
  z-index: 5;

  svg {
    color: #ffffff;
  }

  &:hover {
    cursor: pointer;
    background-color: red;
  }
}

@mixin edit-button {
  position: absolute;
  top: -15px;
  right: -10px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  z-index: 5;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
