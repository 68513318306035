.table_wrapper {
  border: 1px solid #EBEFF1;
  border-radius: 8px;
  padding: 25px 22px 0 22px;

  .actions {
    border-bottom: 1px solid #EBEFF1;
    padding: 0 22px 25px;
    margin-bottom: 25px;
    margin-left: -22px;
    margin-right: -22px;
  }

  .table {
    table-layout: auto;
    width: 100%;
    z-index: 1;
  }

  .table_fixed {
    table-layout: fixed;
  }

  .table_auto {
    table-layout: auto;
  }

  .header {
    .row {
      border: none;
      display: table-row;
      position: relative;

      .column {
        font-family: Rubik, sans-serif;
        font-size: 8px;
        letter-spacing: 1px;
        line-height: 13px;
        font-weight: 400;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  .body {
    padding: 25px;
    position: relative;
  }

  .footer {
    min-width: 100%;

    .row {
      border: none;
    }

    .column {
      text-align: center;
    }
  }
}

.column {
  color: black;
  padding: 15px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 116%;
  vertical-align: middle;

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
    font-weight: 500;
  }
}

.row {
  font-size: 14px;
  line-height: 17.5px;
  border-bottom: 1px solid #EBEFF1;


  &:last-child {
    border-bottom: none;
  }
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  filter: opacity(50%);
  width: 100%;
  height: 100%;
  z-index: 10;
  margin: auto;

}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
}

.no_result {
  color: #214254;
  font-size: 16px;
  line-height: 153%;
  padding: 10px;
}

