.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 0;

  &:hover {
    cursor: move;
  }

  .image_wrapper {
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
