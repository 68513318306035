@import '../../assets/scss/root.scss';

:root {
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1260px;
  --xxl: 1440px;
  --xxxl: 1690px;
  --xxxxl: 1920px;

  //Colors
  --bg-body-color: #e5e5e5;
  --light-blue-color: #f1f7fa;
  --middle-blue-color: #cee5f0;
  --dark-color: #060f14;
  --secondary-color: #214254;
  --primary-color: #4288f0;
  --warning-color: #f6aa47;
  --yellow-color: #ffe545;
  --error-color: #D42948
}

* {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}

.scrollbar {
  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background-color: var(--middle-blue-color);
  }
}

.grid {
  display: grid;
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.raw-gap-20 {
  row-gap: 20px;
}

.raw-gap-10 {
  row-gap: 10px;
}

.column-gap-20 {
  column-gap: 20px;
}

.column-gap-10 {
  column-gap: 10px;
}

.columns-5 {
  columns: 5;
  // gap: 30;
  column-gap: 1.5rem;
  // width: 90%;
  margin: 0 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.h-70 {
  height: 70%;
}

.w-100vw {
  width: 100vw;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.ml-10 {
  margin-left: 10px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-50 {
  margin-right: 50px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.my-20 {
  margin: 40px 0;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.p-0 {
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.blackout {
  position: relative;

  &:hover:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(206, 229, 240, 0.3);
  }
}

.bg-gradient {
  background: linear-gradient(
                  0deg,
                  var(--light-blue-color) 0,
                  var(--light-blue-color) 37%,
                  #fff 37%,
                  #fff 100%
  );
}

.bg-light-blue {
  background-color: var(--light-blue-color);
}

.zIndex-100 {
  z-index: 100;
}
