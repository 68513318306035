.form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: 100%;
  position: relative;
  max-width: 1200px;
  width: 100%;
}

.products_container {
  display: flex;
  flex: 1;
  overflow-x: auto;
  padding-left: 50px;
  margin-left: -50px;
}

.row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  padding: 0;

  & + & {
    border-top: none;
  }
}

.column {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 0;
  border-top: none;
  border-bottom: none;

  & + & {
    border-left: none;
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}

.top_actions {
  position: sticky;
  top: -50px;
  width: 100%;
  display: flex;
  column-gap: 10px;
  background-color: #fff;
  z-index: 10;
  padding: 20px 0;
}

.bottom_actions {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 20px 0;
  background-color: #ffffff;
}
