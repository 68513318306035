@import "../../../assets/scss/mixins.scss";

.page_product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 387px;
  row-gap: 15px;
  text-align: left;
  width: 100%;
  height: 100%;

}

.loading_wrapper {
  position: relative;
  width: 100%;
  height: 50px;
}

.product_input_wrapper {
  max-width: 20%;
  min-width: 20%;
  width: 100%;
  height: 100%;
  min-height: 454px;
  padding: 10px;
}

.import_link {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-align: center;
  font-weight: 300;
}
