.buying_guide {
  padding: 0;
}

.image_container {
  padding: 0;
  width: 100%;
  height: 300px;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
}