.dropdown_indicator {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  color: #739AAC;
}

.clear_indicator {
  width: 10px;
  height: 10px;
  margin-right: 15px;
  color: #739AAC;
  position: relative;
}