@import "../../../assets/scss/mixins.scss";

.page_product_wrapper {
  transition: all 0.2s;
  height: 100%;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.15);
  }

}

.page_product {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupon_button_wrapper {
  display: flex;
  width: 100%;
  padding: 10px 15px 20px 15px;
}

.coupon_btn {
  align-self: self-start;
}

.image {
  width: 120px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-height: 160px;
  }
}

.product_preview {
  position: relative;
  padding: 15px;
  margin: 15px;
  display: flex;
  column-gap: 15px;
  border: 1px solid #F4F9FB;
  border-radius: 8px;

  &__image {
    max-width: 160px;
    max-height: 160px;

    img {
      width: 100%;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: capitalize;
    max-width: 350px;
  }

  &__price {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #0b76ef;
  }

  &__asin {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5a7486;
  }
}

.count_label {
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(-75%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #62D294;
  border-radius: 100%;
  padding: 5px;
  color: white;
  font-size: 12px;
}

.link {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  overflow-wrap: break-word;
  word-break: break-all;
  background: #000;
  font-size: 12px;
  font-weight: 300;
  color: #FFF;
  padding: 10px;
  border-radius: 10px;
  line-height: 14px;
  z-index: 100000000000000000 !important;

  &:hover {
    text-decoration: underline;
  }

}

.tooltip {
  display: flex;
  position: relative;
  z-index: 10;
  width: 100%;
  justify-content: flex-end;
}

.link_visible:hover {
  .link {
    display: flex;
  }
}
