.section_container {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  padding: 0;

  .assets_container {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    gap: 20px;
  }

  .products_slider {
    position: sticky;
    top: -50px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin: -10px;
    z-index: 300;
    background-color: #FFFFFF;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      width: 5px;
      background-color: var(--middle-blue-color);
    }

    &__item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 12px;
      width: 100%;
      box-shadow: 0 0 5px rgb(0, 0, 0, 0.1);

      &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #214254;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12em;
        line-height: 150%;
        margin-bottom: 8px;
        opacity: 0.75;
        overflow: hidden;
        text-transform: capitalize;
      }

      img {
        background-color: #fff;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        box-shadow: 0 5px 20px rgb(21 30 34 / 3%);
        margin-right: 20px;
        min-width: 74px;
        width: 74px;
        height: 74px;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px #c2deeb;
      }

      &__active {
        box-shadow: 0 0 0 3px #c2deeb;
      }
    }

    .item_wrapper {
      padding: 10px;
      width: 25%;
      min-width: 250px;
    }
  }
}

.content_copies_container {
  display: flex;
  column-gap: 10px;
  padding: 10px 0 0 0;
}