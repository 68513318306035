.page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
  width: 100%;
  text-align: start;
  margin-bottom: 15px;
}

.error_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  width: 100%;
  text-align: start;
  margin-bottom: 5px;
}

.error_message {
  font-size: 16px;
  font-weight: 300;
  line-height: 125%;
  color: var(--error-color);
  width: 100%;
  text-align: start;
  padding-bottom: 30px;
}

.buttons_container {
  display: flex;
  width: 100%;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}
