
.linkColumn {
  display: block;
  font-weight: 300;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.hovered {
    white-space: normal;
  }

  &.notHovered {
    white-space: nowrap;
  }
}

.actionColumn {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tableLoadingPlaceholder {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.tableHeader {
  font-family: Rubik, sans-serif;
  font-size: 8px;
  letter-spacing: 1px;
  line-height: 13px;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
}
