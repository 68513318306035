.container {
  border: 1px solid #c2deeb;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.button {
  max-width: 200px;
}

.product {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  max-width: 270px;
  padding: 10px;

  .product_image {
    max-width: 150px;
  }

  .product_title {
    max-width: 250px;
  }
}

.search_field_wrapper {
  position: relative;
}

.reviews_container {
  padding: 20px 10px;
  display: flex;
  overflow-x: auto;
  column-gap: 10px;
}

.prev_asin {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: var(--primary-color);
  padding: 5px 0;
}
