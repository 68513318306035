.notification {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid;
  width: max-content;

  &__info {
    border-color: #3d8eff;
    background-color: #edf4ff;
    color: #505862;

    .icon {
      color: #026bff;
    }
  }

  &__warn {
    border-color: #FFAE3C;
    background-color: #FFF5E8;
    color: #FFAE3C;

    .icon {
      color: #FFAE3C;
    }
  }

  &__error {
    color: #D42948;
    border-color: #D42948;
    background-color: #F6D4DA;
  }

  &__success {
    border-color: #62D294;
    background-color: #E7F8EF;
    color: #62D294;
  }

  &__fullwith {
    width: 100%;
  }

  .icon {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .message {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 15px;
    font-weight: 300;
    min-height: 18px;
    vertical-align: middle;
  }
}