.product_label {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: 13px;
  max-height: 42px;

  .image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: #FFFFFF;
    border-radius: 6px;
    filter: drop-shadow(0 5px 20px rgba(21, 30, 34, 0.03));

    img {
      width: 27px;
      height: 27px;;
    }
  }

  .title_wrapper {
    display: flex;
    flex-direction: column;
    line-height: 100%;
    font-weight: 400;
    color: #214254;
    row-gap: 8px;
    overflow: hidden;
    white-space: nowrap;

    .title {
      position: relative;
      box-sizing: border-box;
      font-size: 14px;
      margin-right: 25px;


      &:visited {
        text-decoration: none;
        color: inherit;
      }

      &:after {
        width: 25px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        content: ' ';
        background: linear-gradient(to left, #FFFFFF, transparent);
      }
    }

    .subtitle {
      font-size: 12px;
      opacity: .7;
    }
  }

}