.tag_creator {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .logo {
    width: 130px;
  }

  .title {
    font-size: 24px;
    line-height: 125%;
    font-weight: 500;
  }

  .hint {
    font-size: 13px;
    line-height: 125%;
    font-weight: 400;
    margin-bottom: 31px;
  }

  .title + .hint {
    margin-top: 16px;
  }

  .logo + .title {
    margin-top: 40px;
  }
}

.temporary_links_table_container {
  width: 1013px;
  margin: 20px 0; 
} 

.temporary_links_search_input {
  margin-bottom: 20px; 
}