@import "assets/scss/root";

.wrapper {
  display: flex;
  position: relative;

  svg {
    width: 20px;
    height: 20px;
    align-self: center;
  }

  svg path {
    fill: $middle-blue;
  }

  input {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
  }

  &__checked {
    svg path {
      fill: #62D294;
    }
  }
}