.order_details {
  display: flex;
  flex-direction: column;
  min-height: 50%;

  .order_items {
    box-sizing: border-box;
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid #EBEFF1;

  }

  .loader_position {
    margin: auto;
  }
}