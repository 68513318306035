.coupon {
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 5px 5px 5px 10px;
  align-items: center;

  &_loading {
    opacity: 0.5;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.title {
  color: #214254;
  font-weight: 500;
  font-size: 16px;
}

.info {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount {
  font-size: 16px;
  font-weight: 600;
  color: #62D294;
}

.dates_container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expired_date_wrapper_container {
  display: flex;
  column-gap: 10px;
}

.date {
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
}

.mark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #62D294;
  border-radius: 100%;
  padding: 5px;
  color: white;

  svg {
    width: 100%;
    height: 100%;
  }
}

.icon_btn {
  cursor: pointer;
  transition: .2s;
  width: 20px;
  height: 20px;
  border-radius: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.edit_icon {
  opacity: .5;

  &:hover {
    opacity: .7;
  }
}

.disabled:hover {
 cursor: no-drop;
}
