.dropdown {
  display: flex;
  position: relative;
  min-width: max-content;

  &__disabled {
    opacity: .5;
  }

  .list {
    position: absolute;
    z-index: 10;
    flex-direction: column;
    padding: 10px;
    box-shadow: 1px 5px 10px rgba(21, 30, 34, 0.2);
    background-color: #fff;
    list-style: none;
    border-radius: 8px;
    overflow: auto;
    font-size: 14px;
    line-height: 116%;
    min-width: max-content;

    &__item {
      padding: 5px 0;
      cursor: pointer;

      &__first {
        font-style: italic;
      }

      &__checked {
        font-weight: 500;
      }
    }

    &__top {
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 5px));
    }

    &__topLeft {
      top: 0;
      left: 0;
      transform: translateY(calc(-100% - 5px));
    }

    &__topRight {
      top: 0;
      right: 0;
      transform: translateY(calc(-100% - 5px));
    }

    &__bottom {
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
    }

    &__bottomLeft {
      top: calc(100% + 5px);
      left: 0;
    }

    &__bottomRight {
      top: calc(100% + 5px);
      right: 0;
    }
  }
}