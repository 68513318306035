.modal {
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 12px;
  border-width: 0;
  max-height: 80%;
  max-width: 80%;
  min-height: 100px;
  min-width: 150px;
  padding: 30px 40px 20px;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 16px;
    line-height: 116%;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__body {
    line-height: 150%;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  &__actions {
    margin-top: 15px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-end;
  }

  &[open] {
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;

    &.modal--closing {
      animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }

  &::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.3);
    backdrop-filter: blur(1px);
    animation: none;
  }
}

@keyframes show{
  from {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes hide{
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }
}