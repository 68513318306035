.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_wrapper {
  display: flex;
  align-items: center;
}
