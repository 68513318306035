.deals_row {;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row_actions {
  padding: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.action_item_wrapper {
 width: max-content;
}

.items_container {
  padding: -10px;
  display: flex;
  width: 100%;
}

.item_wrapper {
  width: calc(100% / 5);
  height: 450px;
  padding: 10px;
}

.error_border {
  border-color: red;
}

.suggestion {
  margin-top: 17px;
}