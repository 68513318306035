@import "src/assets/scss/root";

.button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  transition: .3s;
  cursor: pointer;

  &:focus-visible {
    outline: 0;
    outline-offset: 0;
  }

  &:not(:disabled):active {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }

  &__medium {
    height: 40px;
    padding: 15px;
    font-size: 14px;
    line-height: 116%;
    font-weight: 300;
    border-radius: 10px;
  }

  &__small {
    height: 30px;
    padding: 5px 10px;
    border-radius: 8px;
  }

  &__fullWith {
    width: 100%;
  }

  &__primary {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: #FFFFFF;

    &:not(:disabled):hover {
      color: var(--primary-color);
      background-color: #FFFFFF;
    }

    &__outlined {
      color: var(--primary-color);
      background-color: #FFFFFF;

      &:not(:disabled):hover {
        background-color: var(--primary-color);
        color: #FFFFFF;
      }
    }
  }

  &__secondary {
    border-color: #F4F9FB;
    background-color: #F4F9FB;
    color: #6F8490;

    &:not(:disabled):hover {
      color: #6f8490;
      background-color: inherit;
    }

    &__outlined {
      color: #6F8490;
      background-color: inherit;

      &:not(:disabled):hover {
        background-color: #F4F9FB;
      }
    }
  }
  &__danger {
    border-color: #ff4d4f;
    background-color: #ff4d4f;
    color: #FFFFFF;

    &:not(:disabled):hover {
      color: #ff4d4f;
      background-color: inherit;
    }

    &__outlined {
      color: #ff4d4f;
      background-color: inherit;

      &:not(:disabled):hover {
        background-color: #ff4d4f;
        color: #FFFFFF;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;

    &__hidden {
      opacity: 0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;

    svg {
      width: 100%;
      height: 100%;
    }

    &__large {
      width: 18px;
      height: 18px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .icon + span {
    margin-left: 8px;
  }

  &__loader {
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    width: 26px;
    height: 26px;
    border-radius: 25px;
    border: solid 2px #ddd;
    border-left: solid 2px #999;
  }

  @keyframes loader {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &__loader {
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}