.image_container {
  width: 100%;
  display: flex;
  height: 40%;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 100%;
    max-height: 200px;
  }
}

.fields_container {
  display: flex;
  height: 60%;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
}