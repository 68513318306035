.table {
  thead tr th:nth-child(1) {
    width: 20%;
  }

  thead tr th:nth-child(2) {
    width: 20%;
  }

  thead tr th:nth-child(3) {
    width: 20%;
  }

  thead tr th:nth-child(4) {
    width: 20%;
  }

  thead tr th:nth-child(5) {
    width: 20%;
  }
}