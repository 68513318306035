.list {
  position: relative;
}

.list_items_container {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 5px;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
  min-width: max-content;


  &__bottom-left {
    left: 0;
    top: 100%;
    transform: translate(0, 5px);
  }

  &__bottom-right {
    right: 0;
    top: 100%;
    transform: translate(0, 5px);
  }

  &__bottom {
    left: 50%;
    top: 100%;
    transform: translate(-50%, 5px);
  }
}

.list_item {
  display: flex;
  padding: 5px;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .02);
  }
}

.icon {
  width: 16px;
  height: 16px;
  color: #6F8490;
  margin-bottom: 2px;
  margin-right: 7px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.title {
  color: #214254;
  vertical-align: center;
  line-height: 14px;
  font-size: 14px;
}

