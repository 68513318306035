.author_select {
  width: 100%;
}

.card {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  border-left: 3px solid transparent;
  cursor: pointer;

  &:hover {
    border-left-color: #0b76ef;
  }

  &_checked {
    border-left-color: #0b76ef;
  }

  .avatar {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .avatar_without_image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;
    text-align: center;
    font-size: 16px;
    text-transform: capitalize;
    background: #c2deeb;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 14px;
    color: #214254;
  }

  &__subtitle {
    font-size: 12px;
    color: #6E8490;
  }
}