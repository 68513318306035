@import "src/assets/scss/root";

.nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: auto;
  list-style: none;
  margin-top: 0;
  row-gap: 15px;

  &_item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #6F8490;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    border-radius: 8px;

    & > span {
      font-family: Rubik, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      vertical-align: middle;
      letter-spacing: 0.5px
    }

    &:hover {
      background-color: #F2F7FE;
      color: var(--primary-color);
    }

    svg {
      width: 14px;
      height: 14px;


      svg path {
        fill: #6F8490;
      }
    }

    svg + span {
      margin-left: 12px;
    }

    &__active {
      background-color: #F2F7FE;
      color: var(--primary-color);
    }

    &_hided span {
      display: none;
    }

  }
}
