.items_container {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: -10px;
}

.item_wrapper {
  width: 20%;
  padding: 10px;
  height: 450px;
}
