.pagination {
  display: flex;
  align-items: center;
  min-width: max-content;
  column-gap: 10px;
  gap: 20px;
  padding: 5px;

  &__fullwidth {
    width: 100%;
    justify-content: space-between;
  }

  .text {
    font-size: 13px;
    line-height: 200%;
    font-family: Rubik, sans-serif;
    color: #739AAC;
    font-weight: 400;
  }

  .limit {
    display: flex;
    min-width: 100px
  }

  .actions {
    display: flex;
    column-gap: 5px;
  }

  .offset {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 9px;

    .select {
      max-width: 70px;
    }
  }
}

.arrow_left {
  transform: rotate(90deg);
}

.arrow_right {
  transform: rotate(-90deg);
}
